import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('teams-section__slider')[0]) {
  const teamsSlidersArr = document.querySelectorAll('.teams-section__slider');
  teamsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;*/

    /*if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let teamsSwiper;

    function initSwiper() {
      teamsSwiper = new Swiper(sliderEl, {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 20,
        threshold: 10,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        navigation: {
          prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
          nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
        },
        breakpoints: {
          551: {
            spaceBetween: 16,
            slidesPerView: 2,
          },
          651: {
            spaceBetween: 20,
            slidesPerView: 2,
          },
          901: {
            spaceBetween: 16,
            slidesPerView: 3,
          },
          1025: {
            spaceBetween: 20,
            slidesPerView: 3,
          },
        },
      });

      if (!sliderEl.classList.contains('init-observer')) {
        sliderEl.classList.add('init-observer');
        /*swiperObserver(teamsSwiper);*/
      }
    }

    function handleResize() {
      if (window.innerWidth <= 1020) {
        initSwiper();
      } else {
        if (teamsSwiper) {
          teamsSwiper.destroy();
        }
      }
    }

    initSwiper();
    handleResize();

    /*window.addEventListener('resize', handleResize);*/
  });
}
