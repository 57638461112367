import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/animations.component.js';

/*Sliders*/
import './components/teams.component.js';
import './components/markets.component.js';
import './components/services.component.js';
import './components/case-studies.component.js';
/*Sliders End*/

import './libs/countUp.lib.js';

window.jsScrollToEl = function jsScrollToEl(targetID) {
  let config = targetID.split('|');
  let duration = +config[1] && +config[1] !== '' ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] !== '' ? +config[2] : 0;
  let target = config[0];
  let targetElement = document.querySelector(target);

  if (targetElement) {
    let targetOffsetTop = targetElement.offsetTop - paddingTop;
    window.scrollTo({
      top: targetOffsetTop,
      behavior: 'smooth',
    });
    return false;
  }
};
