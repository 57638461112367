import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('case-studies-section__slider')[0]) {
  const caseStudiesSlidersArr = document.querySelectorAll(
    '.case-studies-section__slider'
  );
  caseStudiesSlidersArr.forEach((sliderEl, sliderIdx) => {
    const pagination = sliderEl.querySelector('.swiper-pagination');
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const caseStudiesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
    });

    swiperObserver(caseStudiesSwiper);
  });
}
